<template>
  <div>
    <div class="d-flex justify-start pb-0"></div>
    <div>
      <div class="d-flex align-center justify-space-between pb-4">
        <label class="text-h6">
          Edit your program audience
        </label>
      </div>
      <ProcoreUsersTable
        :selected="Selected"
        :projectId="projectId"
        :CompanyIdParams="CompanyIdParams"
        :displaySearch="true"
        :displayChips="true"
        :disabled="loading"
        :loading="loadingGroups"
        @select-item="addProcoreUser"
        @remove-item="Selected[$event].selected = false"
      />
      <!-- <v-data-table
        :key="1"
        hide-default-header
        :headers="Headers"
        :items="groupAssignments"
        :loading="loadingGroups"
        loading-text="loading"
        no-data-text="No people found"
        no-results-text="No people found"
        id="table"
        class="cursor-pointer"
        hide-default-footer
        disable-pagination
        @click:row="toggleItem($event)"
      >
        <template v-slot:[`item.remove`]="{ item }">
          <v-simple-checkbox
            @click.stop="toggleItem(item)"
            color="brandCyan"
            :ripple="false"
            :value="!!item.selected"
          />
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span class="word-break">{{ item | name }}</span>
        </template>
        <template v-slot:[`item.attributes`]="{ item }">
          <span class="word-break">
            {{ item.businessEmail || item.businessPhone }}
          </span>
        </template>

        <template v-slot:loading>
          <v-progress-circular
            indeterminate
            color="primary"
            class="my-8"
            :size="50"
            :width="4"
          ></v-progress-circular>
        </template>
      </v-data-table> -->
      <div class="mt-6">
        <v-btn
          color="brandCyan"
          class="white--text"
          rounded
          depressed
          :disabled="!Selected.length"
          :loading="loading"
          @click="submit"
          >Save</v-btn
        >
      </div>
      <p class="error--text mt-2" v-if="errorMessage">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
const moment = require("moment");

import GroupService from "@/services/GroupService";

import ProcoreUsersTable from "@/components/data-table/ProcoreUsers";

export default {
  name: "ProcoreAudienceEditor",
  props: {
    projectId: {
      type: Number
    },
    groups: {
      type: Array
    },
    CompanyIdParams: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: null
    }
  },
  components: { ProcoreUsersTable },
  data() {
    return {
      loadingGroups: false,
      users: []
    };
  },
  created() {
    this.getGroupUsers();
  },
  mounted() {},
  filters: {
    name(val) {
      return (
        val?.displayName ||
        (val?.firstName &&
          val?.lastName &&
          `${val?.firstName} ${val?.lastName}`) ||
        val?.name
      );
    }
  },
  methods: {
    async getGroupUsers() {
      if (!this.groups?.length) return console.log("No groups to fetch");

      this.loadingGroups = true;
      try {
        const groupsToFetch = this.groups.map(x => {
          return x?.groupId || x;
        });

        let filter = `status != 'Deleted' && groupId.isIn([${groupsToFetch}])`;
        let limit = Number.MAX_SAFE_INTEGER;
        let offset = 0;
        let expand = "User";

        const groupAssignmentOptions = {
          filter: filter,
          limit: limit,
          offset: offset,
          expand: expand
        };

        console.log({ groupAssignmentOptions });

        const response = await GroupService.getGroupAssignmentV2(
          groupAssignmentOptions
        );
        console.log("Group assignment res", response);
        this.users = response?.result?.rows
          ?.filter(
            x =>
              x.User &&
              (x?.User?.userIdAtClient?.startsWith("procore|") ||
                x.User.businessEmail)
          )
          ?.map(x => {
            return {
              ...x.User,
              selected: true,
              id: x.User?.userIdAtClient?.split?.("|")?.pop?.()
            };
          });
      } catch (error) {
        console.log("Error ", error);
      } finally {
        this.loadingGroups = false;
      }
    },
    removeUser(userId, procoreId) {
      this.$emit("");
    },
    toggleItem(item) {
      // const existing = this.groupAssignments.findIndex(
      //   x =>
      //     (x.id && item.id && x.id === item.id) ||
      //     (x.userId && item.userId && x.userId === item.userId)
      // );
      // console.log("existing index", existing);
      // if (existing === -1) this.
      item.selected = !item.selected;
    },
    addProcoreUser(user) {
      console.log("addProcoreUser", user);
      const id = user?.id;
      if (!id) return;

      let existingEntry = this.users.find(
        x => x.id === id || user.email_address === x.businessEmail
      );
      // console.log(index);
      if (!existingEntry) this.users.push({ ...user, selected: true });
      else existingEntry.selected = !existingEntry.selected;
      // this.groupAssignments[index].selected = true;
    },
    submit() {
      this.$emit("submit", this.Selected);
    }
  },
  computed: {
    // ComputedSelected: {
    //   get: function() {
    //     return this.groupAssignments.filter(x => x.);
    //   },
    //   set: function(newVal) {
    //     this.$emit("update-start-date", newVal);
    //   }
    // },
    computedEndDate: {
      get: function() {
        return moment(this.endDate).format("YYYY-MM-DD");
      },
      set: function(newVal) {
        this.$emit("update-end-date", newVal);
      }
    },
    computedEndNow: {
      get: function() {
        return this.endNow;
      },
      set: function(newVal) {
        this.$emit("update-end-now", newVal);
      }
    },
    computedTimezone: {
      get: function() {
        return this.timezone;
      },
      set: function(newVal) {
        this.$emit("update-timezone", newVal);
      }
    },
    ReadyToSubmit() {
      return false;
    },
    Headers() {
      return [
        {
          align: "start",
          sortable: false,
          value: "remove",
          width: "35px"
        },
        {
          align: "start",
          sortable: true,
          value: "name",
          width: "50%"
        },
        {
          value: "attributes",
          width: "50%"
        }
      ].filter(Boolean);
    },
    Selected() {
      return this.users.filter(x => x.selected);
    }
  },
  watch: {}
};
</script>

<style lang="css" scoped>
.table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}
</style>
