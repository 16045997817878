<template>
  <v-card flat>
    <v-toolbar dark color="primary" rounded="0">
      <v-toolbar-title v-if="!editingBadge" color="primary">
        Add a new Badge
      </v-toolbar-title>
      <v-toolbar-title v-else color="primary">
        Update a Badge
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="resetBadgeForm(true)">
          <span>Cancel</span>
        </v-btn>
        <v-btn
          color="white"
          text
          @click="createNewBadge"
          :disabled="!newBadgeForm.valid"
          v-if="!editingBadge"
        >
          Save
        </v-btn>
        <v-btn
          color="white"
          text
          @click="updateBadge"
          :disabled="
            (!newBadgeForm.valid && !newBadgeForm.forceValidForm) ||
              newBadge.clientId === 0
          "
          v-else
        >
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider />

    <v-divider />
    <v-container>
      <v-card-text>
        <v-form ref="newBadgeForm" v-model="newBadgeForm.valid">
          <v-autocomplete
            class="pa-1 full-width"
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client*"
            v-model="newBadge.clientId"
            v-if="
              userProfile.clientId === 1 && !newBadgeForm.editing && !clientId
            "
          ></v-autocomplete>
          <!-- <v-text-field
            class="pa-1"
            v-model="newBadge.name"
            :rules="[(v) => !!v || 'Badge name is required']"
            label="Name*"
            required
          ></v-text-field> -->
          <v-text-field
            class="pa-1"
            v-model="newBadge.displayName"
            :rules="[v => !!v || 'Badge name is required']"
            label="Display name*"
          ></v-text-field>
          <v-text-field
            class="pa-1"
            v-model="newBadge.description"
            :rules="[v => !!v || 'Badge description is required']"
            label="Description*"
          ></v-text-field>
          <p class="mt-n1 text-left">
            *The description will be displayed when users receive the badge
          </p>
          <v-select
            v-if="userProfile.clientId == 1"
            class="pa-1"
            :items="type"
            item-text="label"
            item-value="value"
            label="Type*"
            v-model="newBadge.type"
            :rules="[v => !!v || 'Badge type is required']"
          ></v-select>
          <!-- <v-select
            class="pa-1"
            :items="context"
            item-text="label"
            item-value="value"
            label="Context*"
            v-model="newBadge.context"
            :rules="[(v) => !!v || 'Badge context is required']"
          ></v-select> -->

          <v-menu max-width="260" offset-x offset-y absolute>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center justify-space-between full-width image-container mt-5"
              >
                <v-img
                  :src="newBadge.imageUrl"
                  :aspect-ratio="1 / 1"
                  :key="newBadge.imageUrl"
                  v-bind="attrs"
                  v-on="on"
                  max-width="250"
                  max-height="250"
                  class="cursor-pointer content-upload mx-auto"
                  @drop.prevent="dragAndDropImageFileChanged"
                  @dragover.prevent
                >
                  <template v-slot:placeholder>
                    <div class="light-grey-background full-height full-width">
                      <h3 class=" pt-5 primary--text">
                        Add a badge photo
                      </h3>

                      <p class="mt-10">
                        We suggest an image size of 512 x 512 pixels (Or any
                        image with a 1 : 1 (square) aspect ratio).
                      </p>
                      <p>
                        JPG, PNG files supported
                      </p>
                    </div>
                  </template>
                </v-img>
              </div>
            </template>
            <v-list>
              <input
                id="uploader"
                ref="uploader"
                class="d-none"
                type="file"
                accept="image/*"
                @change="imageFileChanged"
                @blur="imageFileChanged"
              />
              <v-list-item @click="uploadImage">
                <v-list-item-title class="primary--text align-text-left"
                  ><v-icon color="black" class="mr-2">mdi-upload</v-icon>Upload
                  from computer</v-list-item-title
                >
              </v-list-item>
              <!-- <v-list-item @click="learningModule.dialogImageUrl = true">
                    <v-list-item-title class="primary--text align-text-left"
                      ><v-icon color="black" class="mr-2">mdi-link</v-icon>By
                      URL</v-list-item-title
                    >
                  </v-list-item> -->
              <v-list-item @click="removeImageFile">
                <v-list-item-title class="primary--text align-text-left"
                  ><v-icon color="black" class="mr-2">mdi-delete</v-icon>Remove
                  file</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <!--<v-menu max-width="260" offset-x offset-y absolute>
             <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center justify-space-between full-width image-container mt-5"
              >
                <v-img
                  :lazy-src="newBadge.certificateImageUrl"
                  :src="newBadge.certificateImageUrl"
                  :key="newBadge.certificateImageUrl"
                  :aspect-ratio="1 / 1"
                  v-bind="attrs"
                  v-on="on"
                  max-width="250"
                  max-height="250"
                  class="cursor-pointer content-upload mx-auto"
                  @drop.prevent="dragAndDropCertificateImageFileChanged"
                  @dragover.prevent
                >
                  <template v-slot:placeholder>
                    <div
                      v-if="newBadge.certificateImageUrl == null"
                      class="light-grey-background full-height full-width"
                    >
                      <h3 class=" pt-5 primary--text">
                        Add a certificate file
                      </h3>

                      <p class="mt-10">
                        Optional: a certificate can be an image or PDF
                      </p>
                    </div>
                    <div v-else>
                      <h3>Certificate File:</h3>
                      <v-icon color="red" x-large>mdi-file-pdf-box</v-icon>
                      <span>{{
                        newBadge.certificateImageUrl &&
                        newBadge.certificateImageUrl.startsWith("blob")
                          ? "New file"
                          : newBadge.certificateImageUrl.substring(
                              newBadge.certificateImageUrl.lastIndexOf("/") + 1
                            )
                      }}</span>
                    </div>
                  </template>
                </v-img>
              </div>
            </template>
            <v-list>
              <input
                id="certUploader"
                ref="certUploader"
                class="d-none"
                type="file"
                accept=".pdf,.png,image/png"
                @change="certificateImageFileChanged"
                @blur="certificateImageFileChanged"
              />
              <v-list-item @click="uploadCertificateImage">
                <v-list-item-title class="primary--text align-text-left"
                  ><v-icon color="black" class="mr-2">mdi-upload</v-icon>Upload
                  from computer</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="removeCertificateFile">
                <v-list-item-title class="primary--text align-text-left"
                  ><v-icon color="black" class="mr-2">mdi-delete</v-icon>Remove
                  file</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-tooltip
          top
          :disabled="!disableDelete"
          v-if="editingBadge && permissions.includes('badges:delete:badge')"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                color="red"
                text
                @click="deleteBadge"
                :disabled="disableDelete"
              >
                Delete
              </v-btn>
            </div>
          </template>
          <span>A behavior is currently tied to this badge</span>
        </v-tooltip>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-container>
    <!-- Image Cropping dialog -->
    <v-dialog persistent v-model="newBadgeForm.dialogImageCropper" width="600">
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-camera</v-icon>
          <v-card-title class="word-break px-0 mx-0">
            Please crop the image below to a 1 : 1 aspect ratio.
          </v-card-title>
        </div>
        <cropper
          ref="cropper"
          :src="newBadge.imageUrlToCrop"
          :stencil-props="{
            aspectRatio: 1 / 1
          }"
        />
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn text @click="closeImageCropDialog(false)">Cancel</v-btn>
          <v-btn text color="primary" @click="closeImageCropDialog(true)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Certificate Image Cropping dialog -->
    <v-dialog
      persistent
      v-model="newBadgeForm.dialogCertificateImageCropper"
      width="600"
    >
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-camera</v-icon>
          <v-card-title class="word-break px-0 mx-0">
            Please crop the image below to a 1 : 1 aspect ratio.
          </v-card-title>
        </div>
        <cropper
          ref="cropper"
          :src="newBadge.certificateImageUrlToCrop"
          :stencil-props="{
            aspectRatio: 1 / 1
          }"
        />
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn text @click="closeCertificateImageCropDialog(false)"
            >Cancel</v-btn
          >
          <v-btn
            text
            color="primary"
            @click="closeCertificateImageCropDialog(true)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-card>
</template>

<script>
import LoadingDialog from "@/components/loadingDialog.vue";
import { mapState } from "vuex";
import BadgeService from "../services/BadgeService.js";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "BadgeCreatorWidget",
  components: {
    LoadingDialog,
    Cropper
  },
  props: {
    editingBadge: Boolean,
    editBadgeObject: Object,
    clientId: Number,
    source: String
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loadingBadgeTable: true,
      badgeTableSearch: null,
      selected: [],
      badges: [],
      type: [
        { label: "Client", value: "CLIENT" },
        { label: "Platform", value: "PLATFORM" },
        { label: "Certification", value: "CERTIFICATION" }
      ],
      context: [
        { label: "Learning", value: "LEARNING" },
        { label: "Surveys", value: "SURVEYS" },
        { label: "Programs", value: "PROGRAM" }
      ],
      categories: ["Cash", "Travel", "Other"],
      showBadgeDialog: false,
      newBadgeForm: {
        menuStartDate: false,
        menuExpirationDate: false,
        expDateDisabled: false,
        expDateCheckBox: false,
        valid: false,
        forceValidForm: false,
        editing: false,
        dialogImageCropper: false,
        dialogCertificateImageCropper: false
      },
      newBadge: {
        clientId: null,
        badgeId: null,
        // name: null,
        displayName: null,
        description: null,
        type: "CLIENT",
        context: null,
        signedUrl: null,
        imageUrlToCrop: null,
        imageUrlFileName: null,
        certificateImageUrlToCrop: null,
        certificateImageUrlFileName: null
      }
    };
  },
  mounted() {
    console.log("Mount");
    // if (!this.editingBadge) this.resetBadgeForm(false);
    if (this.editingBadge) {
      // Sleep 50 ms because Vue hasn't loaded the new form ref yet
      //   setTimeout(() => this.resetBadgeForm(false), 50);
      // } else {
      this.newBadge = JSON.parse(JSON.stringify(this.editBadgeObject));
    } else if (this.clientId) {
      this.newBadge.clientId = this.clientId;
    } else {
      this.newBadge.clientId = this.userProfile.clientId;
    }
    this.newBadge.imageUrlToCrop = null;
    this.newBadge.imageUrlFileName = null;
    this.newBadge.certificateImageUrlToCrop = null;
    this.newBadge.certificateImageUrlFileName = null;
    if (this.source && this.source != null) {
      let context;
      if (this.source == "PROGRAM_WIZARD") {
        context = "PROGRAM";
      } else if (this.source == "LEARNING") {
        context = "LEARNING";
      } else if (this.source == "SURVEYS") {
        context = "SURVEYS";
      }
      if (context != null) {
        this.newBadge.context = context;
      }
    }
  },
  created() {},
  beforeDestroy() {
    //Clears the search in case they go to a page without search
    // this.$store.dispatch("setSearchArray", []);
    // this.unwatch();
  },
  methods: {
    async createNewBadge() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating a new badge";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";

      //set up badge object
      var returnObj = {
        name: this.newBadge.displayName,
        displayName: this.newBadge.displayName,
        description: this.newBadge.description,
        type: this.userProfile.clientId == 1 ? this.newBadge.type : "CLIENT",
        context: this.newBadge.context
      };
      if (this.newBadge.clientId !== null) {
        returnObj.clientId = this.newBadge.clientId;
      }

      if (this.source) returnObj.source = this.source;

      console.log("Badge create: ", returnObj);
      BadgeService.createBadge(returnObj)
        .then(async response => {
          console.log(response);

          if (response && response.badgeId) {
            console.log("badge created");
            if (this.newBadge.imageFile) {
              console.log("Uploading badge image");
              await BadgeService.uploadBadgeImage(
                response.badgeId,
                "image",
                this.newBadge.clientId,
                this.newBadge.imageFile
              );
            }
            if (this.newBadge.certificateImageFile) {
              console.log("Uploading cert image");
              await BadgeService.uploadBadgeImage(
                response.badgeId,
                "certificate",
                this.newBadge.clientId,
                this.newBadge.certificateImageFile
              );
            }
            console.log("Done uploading images");
            this.showLoadingDialog = false;
            this.resetBadgeForm();
            this.$emit("refreshBadges", response.badgeId);
          } else {
            console.log("Error creating badge");
            this.showLoadingDialog = false;
            this.resetBadgeForm();
            this.$emit("refreshBadges", null);
          }
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
        });
    },
    async updateBadge() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Updating badge";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";

      //set up the updated badge object
      var returnObj = {
        badgeId: this.newBadge.badgeId,
        name: this.newBadge.displayName,
        displayName: this.newBadge.displayName,
        description: this.newBadge.description,
        type: this.newBadge.type,
        context: this.newBadge.context
      };

      if (this.newBadge.clientId !== null) {
        returnObj.clientId = this.newBadge.clientId;
      }

      console.log("Badge update, ", returnObj);
      BadgeService.updateBadge(this.newBadge.badgeId, returnObj)
        .then(async response => {
          console.log(response);

          if (response) {
            console.log("Badge updated");
            if (this.newBadge.imageFile) {
              console.log("Uploading badge image");
              await BadgeService.uploadBadgeImage(
                this.newBadge.badgeId,
                "image",
                this.newBadge.clientId,
                this.newBadge.imageFile
              );
            } else if (
              this.editBadgeObject &&
              this.editBadgeObject.imageUrl &&
              !this.newBadge.imageUrl
            ) {
              console.log("Deleting old badge image");
              await BadgeService.deleteBadgeImage(
                this.newBadge.badgeId,
                "image",
                this.newBadge.clientId
              );
            }

            console.log(this.editBadgeObject);
            if (this.newBadge.certificateImageFile) {
              console.log("Uploading cert image");
              await BadgeService.uploadBadgeImage(
                this.newBadge.badgeId,
                "certificate",
                this.newBadge.clientId,
                this.newBadge.certificateImageFile
              );
            } else if (
              this.editBadgeObject &&
              this.editBadgeObject.certificateImageUrl &&
              !this.newBadge.certificateImageUrl
            ) {
              console.log("Deleting old badge cert image");
              await BadgeService.deleteBadgeImage(
                this.newBadge.badgeId,
                "certificate",
                this.newBadge.clientId
              );
            }
            this.showLoadingDialog = false;
            this.resetBadgeForm();
            this.$emit("refreshBadges", null);
          } else {
            console.log("Error updating badge");
          }
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
        });
    },
    deleteBadge() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Deleting badge";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      BadgeService.deleteBadge(this.newBadge.badgeId)
        .then(response => {
          console.log(response);
          this.showLoadingDialog = false;
          if (response) {
            console.log("Badge deleted");
            this.resetBadgeForm();
            this.$emit("refreshBadges", null);
          } else {
            console.log("Error updating badge");
          }
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
        });
    },

    resetBadgeForm(closeForm = true) {
      console.log("reset badge form");
      //this.showBadgeDialog = false;
      this.newBadgeForm.forceValidForm = false;
      this.newBadgeForm.showLoadingDialog = false;
      // this.newBadgeForm.editing = false;

      this.$refs.newBadgeForm.reset();

      if (this.newBadge.clientId == null) {
        if (this.clientId) {
          this.newBadge.clientId = this.clientId;
        } else {
          this.newBadge.clientId = this.userProfile.clientId;
        }
      }

      if (closeForm == true) {
        this.$emit("close");
      }
    },
    uploadImage() {
      this.$refs.uploader.click();
    },
    uploadCertificateImage() {
      this.$refs.certUploader.click();
    },
    imageFileChanged(e) {
      if (e.target.files.length > 0) {
        console.log(e.target.files[0]);
        this.$set(
          this.newBadge,
          "imageUrlToCrop",
          URL.createObjectURL(e.target.files[0])
        );
        this.$set(this.newBadge, "imageUrlFileName", e.target.files[0].name);

        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#uploader").value = "";
        this.newBadgeForm.dialogImageCropper = true;
      }
    },
    dragAndDropImageFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        console.log(e.dataTransfer.files[0]);
        this.$set(
          this.newBadge,
          "imageUrlToCrop",
          URL.createObjectURL(e.dataTransfer.files[0])
        );
        this.$set(
          this.newBadge,
          "imageUrlFileName",
          e.dataTransfer.files[0].name
        );

        this.newBadgeForm.dialogImageCropper = true;
      }
    },
    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      if (submitPressed) {
        const { canvas } = this.$refs.cropper.getResult();
        this.newBadge.imageUrl = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.newBadge.imageUrlFileName);
          this.newBadge.imageFile = file;

          //Regardless, we close dialog
          this.newBadgeForm.dialogImageCropper = false;
          this.newBadge.imageUrlToCrop = null;
          this.newBadge.imageUrlFileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.newBadgeForm.dialogImageCropper = false;
        this.newBadge.imageUrlToCrop = null;
        this.newBadge.imageUrlFileName = null;
      }
    },
    certificateImageFileChanged(e) {
      if (e.target.files.length > 0) {
        console.log(e.target.files[0]);
        this.$set(
          this.newBadge,
          "certificateImageUrlToCrop",
          URL.createObjectURL(e.target.files[0])
        );
        this.$set(
          this.newBadge,
          "certificateImageUrlFileName",
          e.target.files[0].name
        );
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#certUploader").value = "";
        this.newBadgeForm.dialogCertificateImageCropper = true;
      }
    },
    dragAndDropCertificateImageFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        console.log(e.dataTransfer.files[0]);
        this.$set(
          this.newBadge,
          "certificateImageUrlToCrop",
          URL.createObjectURL(e.dataTransfer.files[0])
        );
        this.$set(
          this.newBadge,
          "certificateImageUrlFileName",
          e.dataTransfer.files[0].name
        );
        this.newBadgeForm.dialogCertificateImageCropper = true;
      }
    },
    closeCertificateImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      if (submitPressed) {
        const { canvas } = this.$refs.cropper.getResult();
        this.newBadge.certificateImageUrl = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File(
            [blob],
            this.newBadge.certificateImageUrlFileName
          );
          this.newBadge.certificateImageFile = file;

          //Regardless, we close dialog
          this.newBadgeForm.dialogCertificateImageCropper = false;
          this.newBadge.certificateImageUrlToCrop = null;
          this.newBadge.certificateImageUrlFileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.newBadgeForm.dialogCertificateImageCropper = false;
        this.newBadge.certificateImageUrlToCrop = null;
        this.newBadge.certificateImageUrlFileName = null;
      }
    },
    removeImageFile() {
      this.newBadge.imageUrl = null;
      this.newBadge.imageFile = null;
    },
    removeCertificateFile() {
      this.newBadge.certificateImageUrl = null;
      this.newBadge.certificateImageFile = null;
    }
  },
  computed: {
    ...mapState(["clients", "userProfile", "postItArray", "permissions"]),
    disableDelete() {
      var currentBadge = this.newBadge.badgeId;
      var index = this.badges.findIndex(x => x.badgeId === currentBadge);
      if (index !== -1 || this.newBadge.clientId === 0) {
        return true;
      } else {
        return false;
      }
    },
    calculatedImageWidth() {
      // This is used for the image width because some images want to expand outside of the container, so I get that width and subtract the width of the edit icon
      // if (!this.mounted) {
      //   console.log("not mounted");
      //   return;
      // }
      if (document.getElementsByClassName("image-container").length > 0) {
        return (
          document.getElementsByClassName("image-container")[0].offsetWidth - 60
        );
      } else {
        return 600;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
