<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" :class="{ 'pointer-events-none': disabled }">
        <div class="rewardees" v-if="displayChips">
          <v-chip
            class="mx-2 my-2 audience-chip"
            v-for="(person, idx) in selected"
            :key="person.id"
            close
            outlined
            color="brandCyan"
            @click:close="removeItem(idx)"
          >
            <span class="black--text">{{ person | name }}</span>
          </v-chip>
        </div>
        <v-text-field
          v-if="displaySearch && visibleItems?.length"
          v-model="table.search"
          placeholder="Start typing a name or email"
          color="brandCyan"
        >
        </v-text-field>
        <v-data-table
          :key="1"
          hide-default-header
          :headers="headers"
          :items="visibleItems"
          :loading="table.loading || loading"
          loading-text="loading"
          must-sort
          sort-by="displayName"
          sort-asc
          no-data-text="No people found"
          no-results-text="No people found"
          item-key="id"
          id="user-table"
          class="cursor-pointer"
          :search="displaySearch ? table.search : undefined"
          @click:row="selectItem($event, false)"
          >.
          <template v-slot:[`item.select`]="{ item }">
            <v-simple-checkbox
              color="brandCyan"
              @click.stop="selectItem(item, false)"
              :ripple="false"
              :value="
                selected.find(
                  x =>
                    (x.id && item.id && x.id == item.id) ||
                    (x.businessEmail &&
                      item.email_address &&
                      x.businessEmail?.toLowerCase() ===
                        item?.email_address?.toLowerCase())
                )
                  ? true
                  : false
              "
            />
          </template>
          <!-- :value="selected.find(x => x.id == item.id) ? true : false" -->
          <template v-slot:[`item.name`]="{ item }">
            <span class="word-break">{{ item.name }}</span>
          </template>
          <template v-slot:[`item.attributes`]="{ item }">
            <div class="d-flex flex-column text-left word-break">
              <span> {{ item | vendor }}</span>
              <span> {{ item | jobTitle }}</span>
            </div>
          </template>

          <template v-slot:loading>
            <!-- {{ item }} -->
            <v-progress-circular
              indeterminate
              color="primary"
              class="my-8"
              :size="50"
              :width="4"
            ></v-progress-circular>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProgramService from "@/services/ProgramService";

import { mapState } from "vuex";

export default {
  name: "ProcoreUsersTable",
  props: {
    projectId: {
      type: Number
    },
    CompanyIdParams: {
      type: Object
    },
    selected: {
      type: Array
    },
    People: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    },
    displaySearch: {
      type: Boolean,
      default: false
    },
    displayChips: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      cache: {},
      item: null,
      table: {
        search: null,
        loading: false,
        items: []
      }
    };
  },
  created() {
    this.getUsers("created");
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async getUsers(source = null) {
      if (this.People?.length) return;
      try {
        console.log("Fetching users from within ProcoreUsers");
        this.table.loading = true;
        const PARTICIPANT = 0;
        return ProgramService.getProcorePeopleInProject(
          this.projectId,
          this.CompanyIdParams
        )
          .then(res => {
            console.log("Got procore people", res.data);
            this.table.items = res.data.map(p => ({
              ...p,
              $permission: PARTICIPANT
            }));
            if (!this.cache[this.projectId]) this.cache[this.projectId] = {};
            this.cache[this.projectId].people = this.table.items.slice();
          })
          .catch(() => {
            this.$emit("handle-error", "people");
          })
          .finally(() => {
            this.table.loading = false;
          });
      } catch (err) {
        console.log("Error getting procore users ", err);
        this.table.loading = false;
      }
    },
    selectItem(x) {
      console.log("Selecting item ", x);
      this.$emit("select-item", x);
    },
    removeItem(index) {
      this.$emit("remove-item", index);
    }
  },
  filters: {
    vendor(person) {
      return person?.vendor?.name || "";
    },
    jobTitle(person) {
      return person?.job_title || "";
    },
    name(val) {
      return (
        val?.displayName ||
        (val?.firstName &&
          val?.lastName &&
          `${val?.firstName} ${val?.lastName}`) ||
        val?.name
      );
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "permissions",
      "globalClientId",
      "clients",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    visibleItems() {
      if (this.People) return this.People;
      let array = this.table.items || [];

      // array = array.filter(x => x.status != "Deleted");

      if (this.table.loading || this.loading) return [];
      return array;
    },
    headers() {
      return [
        [
          {
            align: "start",
            sortable: false,
            value: "select",
            width: "35px"
          },
          {
            align: "start",
            sortable: true,
            value: "name",
            width: "50%"
          },
          {
            value: "attributes",
            width: "50%"
          }
        ],
        // Makes these fields searchable
        this.displaySearch
          ? [
              { value: "email_address", align: " d-none" },
              { value: "job_title", align: " d-none" },
              { value: "vendor.name", align: " d-none" }
            ]
          : []
      ]
        .flat()
        .filter(Boolean);
    }
  },
  watch: {
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null) {
        this.getUsers(true, "client watcher");
      }
    },
    projectId: function(newVal, oldVal) {
      console.log("projectId watcher", { newVal, oldVal });
      if (newVal != oldVal) {
        this.getUsers(true, "project watcher");
      }
    }
  }
};
</script>

<style scoped>
.rewardees {
  min-height: 2.5em;
}

/* Changes the close icon's color for selected audience chips */
.audience-chip >>> .v-chip__close {
  color: lightgray;
}

.audience-chip {
  max-width: 260px;
  white-space: pre-wrap;
  min-height: 32px;
  height: auto;
}
</style>
