var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{class:{ 'pointer-events-none': _vm.disabled },attrs:{"cols":"12"}},[(_vm.displayChips)?_c('div',{staticClass:"rewardees"},_vm._l((_vm.selected),function(person,idx){return _c('v-chip',{key:person.id,staticClass:"mx-2 my-2 audience-chip",attrs:{"close":"","outlined":"","color":"brandCyan"},on:{"click:close":function($event){return _vm.removeItem(idx)}}},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm._f("name")(person)))])])}),1):_vm._e(),(_vm.displaySearch && _vm.visibleItems?.length)?_c('v-text-field',{attrs:{"placeholder":"Start typing a name or email","color":"brandCyan"},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}):_vm._e(),_c('v-data-table',{key:1,staticClass:"cursor-pointer",attrs:{"hide-default-header":"","headers":_vm.headers,"items":_vm.visibleItems,"loading":_vm.table.loading || _vm.loading,"loading-text":"loading","must-sort":"","sort-by":"displayName","sort-asc":"","no-data-text":"No people found","no-results-text":"No people found","item-key":"id","id":"user-table","search":_vm.displaySearch ? _vm.table.search : undefined},on:{"click:row":function($event){return _vm.selectItem($event, false)}},scopedSlots:_vm._u([{key:`item.select`,fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"color":"brandCyan","ripple":false,"value":_vm.selected.find(
                x =>
                  (x.id && item.id && x.id == item.id) ||
                  (x.businessEmail &&
                    item.email_address &&
                    x.businessEmail?.toLowerCase() ===
                      item?.email_address?.toLowerCase())
              )
                ? true
                : false},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item, false)}}})]}},{key:`item.name`,fn:function({ item }){return [_c('span',{staticClass:"word-break"},[_vm._v(_vm._s(item.name))])]}},{key:`item.attributes`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column text-left word-break"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("vendor")(item)))]),_c('span',[_vm._v(" "+_vm._s(_vm._f("jobTitle")(item)))])])]}},{key:"loading",fn:function(){return [_c('v-progress-circular',{staticClass:"my-8",attrs:{"indeterminate":"","color":"primary","size":50,"width":4}})]},proxy:true}],null,true)},[_vm._v(". ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }