<template>
  <div class="">
    <div class="d-flex justify-start pb-4">
      <label class="text-h6">
        Choose your program timeframe
      </label>
    </div>
    <div class="d-flex justify-start pb-0"></div>
    <div class="d-flex justify-space-between">
      <div class="mt-2">
        <span>Start Date</span>
        <v-tooltip top v-if="HasStarted"
          ><template v-slot:activator="{ on, attrs }"
            ><v-icon v-on="on" v-bind="attrs" class="ml-2"
              >mdi-help-circle-outline</v-icon
            ></template
          ><span
            >The program has already started, so only the end date is
            editable.</span
          ></v-tooltip
        >
      </div>
      <span v-if="HasStarted">{{ computedStartDate | formatDate }}</span>
      <v-text-field
        v-else
        style="width: 200px; max-width: 50%;"
        type="date"
        color="brandCyan"
        label="Start date"
        outlined
        dense
        v-model="computedStartDate"
        :menu-props="{ offsetY: true }"
        hide-details
      >
      </v-text-field>
    </div>
    <div class="d-flex justify-space-between mt-8">
      <div class="mt-2">
        <span>End Date</span>
        <v-tooltip top v-if="HasEnded"
          ><template v-slot:activator="{ on, attrs }"
            ><v-icon v-on="on" v-bind="attrs" class="ml-2"
              >mdi-help-circle-outline</v-icon
            ></template
          ><span
            >The program has already ended, so the end date is locked.</span
          ></v-tooltip
        >
      </div>
      <span v-if="HasEnded">{{ computedEndDate | formatDate }}</span>
      <v-text-field
        v-else
        style="width: 200px; max-width: 50%;"
        type="date"
        color="brandCyan"
        label="End date"
        outlined
        dense
        v-model="computedEndDate"
        :menu-props="{ offsetY: true }"
        hide-details
        :disabled="computedEndNow"
      >
      </v-text-field>
    </div>
    <div class="d-flex justify-end" v-if="HasStarted && !HasEnded">
      <v-checkbox
        label="End now"
        color="brandCyan"
        v-model="computedEndNow"
      ></v-checkbox>
    </div>
    <div class="d-flex justify-space-between mt-8">
      <div class="mt-2">
        <span>Timezone</span>
        <v-tooltip top v-if="HasEnded"
          ><template v-slot:activator="{ on, attrs }"
            ><v-icon v-on="on" v-bind="attrs" class="ml-2"
              >mdi-help-circle-outline</v-icon
            ></template
          ><span
            >The program has already ended, so the end date is locked.</span
          ></v-tooltip
        >
      </div>
      <span v-if="HasEnded && HasStarted">{{ computedTimezone }}</span>
      <v-autocomplete
        v-else
        class="ml-1"
        color="brandCyan"
        style="max-width: 50%"
        v-model="computedTimezone"
        :items="timezones"
        label="Timezone"
        placeholder="Timezone"
        outlined
        dense
      >
      </v-autocomplete>
    </div>

    <p class="error--text mt-1" v-if="dateErrorMessage">
      {{ dateErrorMessage }}
    </p>
  </div>
</template>

<script>
const moment = require("moment-timezone");

export default {
  name: "ProcoreScheduleEditor",
  props: {
    HasStarted: {
      type: Boolean,
      default: false
    },
    HasEnded: {
      type: Boolean,
      default: false
    },
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    timezone: {
      type: String
    },
    timezones: {
      type: Array
    },
    endNow: {
      type: Boolean,
      default: false
    },
    dateErrorMessage: {
      type: String
    }
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  filters: {
    formatDate(val) {
      return moment(val).format("MMM DD, YYYY");
    }
  },
  methods: {},
  computed: {
    computedStartDate: {
      get: function() {
        return moment.tz(this.startDate, this.timezone).format("YYYY-MM-DD");
      },
      set: function(newVal) {
        this.$emit("update-start-date", newVal);
      }
    },
    computedEndDate: {
      get: function() {
        return moment.tz(this.endDate, this.timezone).format("YYYY-MM-DD");
      },
      set: function(newVal) {
        this.$emit("update-end-date", newVal);
      }
    },
    computedEndNow: {
      get: function() {
        return this.endNow;
      },
      set: function(newVal) {
        this.$emit("update-end-now", newVal);
      }
    },
    computedTimezone: {
      get: function() {
        return this.timezone;
      },
      set: function(newVal) {
        this.$emit("update-timezone", newVal);
      }
    },
    ReadyToSubmit() {
      return false;
    }
  },
  watch: {}
};
</script>

<style lang="css" scoped></style>
