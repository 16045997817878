<template>
  <div class="">
    <div class="d-flex justify-start pb-4">
      <label class="text-h6">
        Choose your rewards
      </label>
    </div>
    <div class="d-flex justify-start pb-0"></div>
    <div class="d-flex justify-space-between">
      <div class="mt-2">
        <span>Reward</span>
        <v-tooltip top
          ><template v-slot:activator="{ on, attrs }"
            ><v-icon v-on="on" v-bind="attrs" class="ml-2"
              >mdi-help-circle-outline</v-icon
            ></template
          ><span v-if="IsSubmittal"
            >Amount earned if the submittals meet the program requirements on
            the end date.</span
          ><span v-else-if="!isDailyLog"
            >Amount rewarded each time the participant meets the program
            requirements.</span
          ><span v-else
            >Amount rewarded each day the participant marks the log "complete"
            with all program requirements met.</span
          ></v-tooltip
        >
      </div>
      <v-text-field
        class="pr-1"
        style="width: 200px; max-width: 50%;"
        type="number"
        color="brandCyan"
        label="Reward"
        :prefix="IsCashBudget ? '$' : ''"
        outlined
        dense
        v-model.number="computedReward"
        placeholder="Reward"
        :rules="rewardRules"
        :menu-props="{ offsetY: true }"
      >
      </v-text-field>
    </div>
    <template v-if="!IsSubmittal">
      <div class="d-flex justify-space-between">
        <div class="mt-2">
          <span>Initial Bonus</span>
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                >mdi-help-circle-outline</v-icon
              ></template
            ><span
              >Optional one-time reward for the first time participants meet
              program requirements.</span
            ></v-tooltip
          >
        </div>
        <v-text-field
          class="pr-1"
          style="width: 200px; max-width: 50%;"
          type="number"
          color="brandCyan"
          :prefix="IsCashBudget ? '$' : ''"
          label="Initial Bonus"
          outlined
          dense
          v-model.number="computedInitialBonus"
          placeholder="Initial Bonus"
          :rules="bonusRules"
          :menu-props="{ offsetY: true }"
        >
        </v-text-field>
      </div>
      <div class="d-flex justify-space-between">
        <div class="mt-2">
          <span>Streak Bonus</span>
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                >mdi-help-circle-outline</v-icon
              ></template
            ><span
              >Optional one-time reward for participants that meet the program
              requirements for the full duration of the program.</span
            ></v-tooltip
          >
        </div>
        <v-text-field
          class="pr-1"
          style="width: 200px; max-width: 50%;"
          type="number"
          color="brandCyan"
          label="Streak Bonus"
          :prefix="IsCashBudget ? '$' : ''"
          outlined
          dense
          v-model.number="computedStreakBonus"
          placeholder="Streak Bonus"
          :rules="bonusRules"
        >
        </v-text-field>
      </div>
    </template>
    <div class="d-flex flex-column">
      <div class="d-flex justify-start pb-0 flex-column pr-1">
        <label class="text--disabled body-2 align-self-start mb-2">
          Budget source
        </label>
      </div>
      <div class="d-flex justify-space-between">
        <v-select
          :loading="LoadingBudgets"
          outlined
          dense
          hide-details
          color="brandCyan"
          v-model="computedBudget"
          placeholder="Select a budget"
          :rules="budgetRules"
          item-text="text"
          item-value="value"
          :items="Budgets"
          :menu-props="{ offsetY: true }"
          :no-data-text="
            LoadingBudgets ? 'Loading budgets...' : 'No budgets available'
          "
        >
        </v-select>
        <!-- <v-spacer /> -->
      </div>
    </div>
    <!-- <template v-else>
      <div class="d-flex justify-space-between">
        <div class="mt-2">
          <span>Reward</span>
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon v-on="on" v-bind="attrs" class="ml-2"
                >mdi-help-circle-outline</v-icon
              ></template
            ><span
              ></span
            ></v-tooltip
          >
        </div>
        <v-text-field
          class="pr-1"
          style="width: 200px; max-width: 50%;"
          type="number"
          color="brandCyan"
          label="Reward"
          :prefix="IsCashBudget ? '$' : ''"
          outlined
          dense
          v-model.number="computedReward"
          placeholder="Reward"
          :rules="rewardRules"
          :menu-props="{ offsetY: true }"
        >
        </v-text-field>
      </div>
    </template> -->
    <!-- <div class="d-flex flex-column">
      <div class="d-flex justify-start pb-0 flex-column pr-1">
        <label class="text--disabled body-2 align-self-start mb-2">
          Budget source
        </label>
      </div>
      <div class="d-flex justify-space-between">
        <v-select
          :loading="display.loading.budget"
          outlined
          dense
          hide-details
          color="brandCyan"
          v-model="input.rewardBudget"
          placeholder="Select a budget"
          :rules="validation.budget"
          item-text="text"
          item-value="value"
          :items="BudgetOptions"
          :menu-props="{ offsetY: true }"
          :no-data-text="
            display.loading.projects
              ? 'Loading budgets...'
              : 'No budgets available'
          "
        >
        </v-select>
        <v-spacer />
      </div>
      <div class="d-flex justify-end full-width mt-2">
        <v-btn
          v-if="Budget"
          outlined
          rounded
          dark
          x-small
          color="primary"
          @click="display.fundingBudget = true"
          >Add funds</v-btn
        >
      </div>
      <em class="red--text text-left" v-if="InsufficientFunds">
        Warning: Insufficient funds - program may not pay out. Please select a
        different budget or click "Add funds"
      </em>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "ProcoreRewardEditor",
  props: {
    IsSubmittal: {
      type: Boolean,
      default: false
    },
    isDailyLog: {
      type: Boolean,
      default: false
    },
    IsCashBudget: {
      type: Boolean,
      default: false
    },
    LoadingBudgets: {
      type: Boolean,
      default: false
    },
    Budgets: {
      type: Array
    },
    reward: {
      type: Number
    },
    initialBonus: {
      type: Number
    },
    streakBonus: {
      type: Number
    },
    rewardRules: {
      type: Array
    },
    bonusRules: {
      type: Array
    },
    budgetId: {
      type: Number
    },
    budgetRules: {
      type: Array
    }
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  filters: {},
  methods: {},
  computed: {
    computedReward: {
      get: function() {
        return this.reward;
      },
      set: function(newVal) {
        this.$emit("update-reward", newVal);
      }
    },
    computedInitialBonus: {
      get: function() {
        return this.initialBonus;
      },
      set: function(newVal) {
        this.$emit("update-initial", newVal);
      }
    },
    computedStreakBonus: {
      get: function() {
        return this.streakBonus;
      },
      set: function(newVal) {
        this.$emit("update-streak", newVal);
      }
    },
    computedBudget: {
      get: function() {
        return this.budgetId;
      },
      set: function(newVal) {
        this.$emit("update-budget", newVal);
      }
    }
  },
  watch: {}
};
</script>

<style lang="css" scoped></style>
