<template>
  <v-dialog v-model="showDialog" persistent width="300">
    <v-card class="px-4 py-6">
      <v-card-text class="d-flex flex-column justify-center align-center pb-0">
        <h2>{{ header }}</h2>
        <lottie
          v-if="displayRobin"
          :options="lottieOptions"
          :height="170"
          :width="170"
          v-on:animCreated="handleAnimation"
        />
        <v-progress-circular
          v-else
          class="my-4"
          indeterminate
          :size="50"
          :width="4"
          color="brandCyan"
        />

        <span
          class="line1"
          v-if="
            (messageArray == undefined || messageArray.length == 0) && line1
          "
          >{{ line1 }} <br
        /></span>

        <span
          class="line2"
          v-if="
            (messageArray == undefined || messageArray.length == 0) && line2
          "
          >{{ line2 }} <br
        /></span>

        <transition name="fade" mode="out-in" v-if="messageArray != undefined"
          ><span key="1" v-if="itemFlipper">{{ arrayItem }}</span>
          <span key="2" v-else>{{ arrayItem }}</span></transition
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Eating.json";
export default {
  name: "Loading",
  components: { Lottie },
  props: {
    showDialog: Boolean,
    header: String,
    line1: String,
    line2: String,
    messageArray: Array,
    displayRobin: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      randInt: 0,
      lottieOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true
      },
      anim: null,
      animationSpeed: 1,
      arrayItem: this.line1,
      itemFlipper: true
    };
  },
  created() {
    this.randInt = Math.floor(Math.random() * Math.floor(7));
  },
  mounted() {
    this.rotateArray();
  },
  updated() {
    this.randInt = Math.floor(Math.random() * Math.floor(7));
  },

  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    rotateArray: function() {
      if (this.messageArray != undefined && this.messageArray.length > 0) {
        const it = this.messageArray[Symbol.iterator](); // convenient for yeilding values
        const int = setInterval(() => {
          // time interval
          const next = it.next(); // next value
          if (!next.done) {
            // done = true when the end of array reached
            this.arrayItem = next.value; // concatenate word to the string
            this.itemFlipper = !this.itemFlipper;
          } else {
            clearInterval(int); // when done - clear interval
          }
        }, 4000); // interval duration, 3s
      }
    }
  },
  computed: {}
};
</script>

<style>
.rotate {
  height: 75px;
  width: 75px;
  animation: rotation 2.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
