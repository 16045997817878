import axios from "axios";
import https from "https";

let opt = {};
const agent = new https.Agent({
  rejectUnauthorized: false
});
if (process.env.VUE_APP_ENVIRONMENT == "dev") {
  opt = {
    httpsAgent: agent
  };
}

export var main = axios.create({
  httpsAgent: agent
});

// main.onRequest(config => {
//   if (process.env.dev) {
//     config.httpsAgent = agent;
//   }
// });

// This instance is used so we don't touch the main instance
// We replace the auth token here with either a specific Auth0 token or a Whistle custom JWT
export var noAuth = axios.create(opt);

// Whistle OAuth axios
export var oauth = axios.create({
  headers: { "x-whistle-oauth": 1 },
  ...opt
});

export default main;
